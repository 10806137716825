import { useUserListOrganisations } from "@gocardless/api/dashboard/user";
import { useOrganisation } from "src/queries/organisation";

export const useOrgSwitcherData = () => {
  const loggedInOrganisation = useOrganisation() ?? {};

  const {
    data: { list_organisations: organisationsList } = {},
    isLoading: isLoadingListOrganisations,
    error: listOrganisationsError,
  } = useUserListOrganisations();

  const parentOrganisation = organisationsList?.find((org) => org.depth === 0);
  const currentOrganisation = organisationsList?.find(
    (org) => org.id === loggedInOrganisation.id
  );

  const sortedChildOrganisations =
    organisationsList
      ?.filter((org) => org.depth === 1 && org.id !== loggedInOrganisation?.id)
      .sort(
        (a, b) =>
          (a.nickname || a.name || "")?.localeCompare(
            b.nickname || a.name || ""
          )
      )
      .slice(0, 2) ?? [];

  return {
    organisations: [
      ...(parentOrganisation &&
      parentOrganisation.id !== loggedInOrganisation.id
        ? [parentOrganisation]
        : []),
      ...sortedChildOrganisations,
    ],
    loggedInOrganisation: currentOrganisation,
    parentOrganisation,
    isLoading: isLoadingListOrganisations,
    error: listOrganisationsError,
  };
};

import { useOrganisation } from "src/queries/organisation";

export const useMultiEntityFeature = () => {
  const hasMultiEntityFeature: boolean =
    useOrganisation()?.features?.["multi_entity_organisation_tree"] ?? false;

  return {
    hasMultiEntityFeature,
  };
};
